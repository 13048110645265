import React, { Component } from 'react'
import './Questions.scss'
import 'react-slidedown/lib/slidedown.css'
import Question from 'components/Questions/Question/Question'

export class Questions extends Component {

    state = {
        openQuestions: []
    }

    questionSlide = questionNumber => {
        const openQuestions = [...this.state.openQuestions]

        const index = openQuestions.indexOf(questionNumber)

        if (index === -1) {
            openQuestions.push(questionNumber)
        } else {
            openQuestions.splice(index, 1)
        }

        this.setState({
            openQuestions
        })
    }

    render() {

        const {
            openQuestions
        } = this.state

        const {
            questions,
            backgroundImage
        } = this.props

        return (
            <div className="questions" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="content">
                    <ul>
                        {questions.map((question, index) => {
                            const isOpen = openQuestions.indexOf(index) !== -1

                            return (
                                <Question
                                    index={index}
                                    question={question.question}
                                    answer={question.answer}
                                    questionSlide={this.questionSlide}
                                    isOpen={isOpen}
                                    openQuestions={openQuestions}
                                    key={index}
                                />
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }

}

export default Questions;
import React, { Component } from 'react';
import Hero from 'components/Hero/Hero';
import Questions from 'components/Questions/Questions';
import LayoutWrapper from 'src/layouts/LayoutWrapper'

class HomePage extends Component {
    render() {
        const {
            data,
            location
        } = this.props

        const thisPageData = data.contentfulHomePage

        return (
            <LayoutWrapper>
                <div>
                    <Hero
                        backgroundImage={thisPageData.heroBackgroundImage.file.url}
                        title={thisPageData.heroTitle}
                        copy={thisPageData.heroCopy.heroCopy}
                        searchPlaceholder={thisPageData.heroSearchPlaceholder}
                        searchButtonLabel={thisPageData.heroSearchButtonLabel}
                        isHomepage={true}
                        location={location}
                    />
                    <Questions
                        backgroundImage={thisPageData.questionsBackgroundImage.file.url}
                        questions={thisPageData.questions}
                    />
                </div>
            </LayoutWrapper>
        )

    }
};

export default HomePage;

export const homePageQuery = graphql`
  query homePageQuery($id: String!) {
    siteSearchIndex {
      index
    }
    contentfulHomePage(id: { eq: $id }) {
      __typename
      ... on ContentfulHomePage {
        heroTitle
        heroSearchPlaceholder
        heroSearchButtonLabel
        heroCopy {
          heroCopy
        }
        heroBackgroundImage {
          file {
            url
          }
        }
        questionsBackgroundImage {
          file {
            url
          }
        }
        questions {
          question
          answer {
            json
          }
        }
      }
    }
  }
`;
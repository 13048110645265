import React, { Component } from 'react'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { getImage } from 'utils/get-image'

import { processJson } from 'utils'

export default class Question extends Component {
    render() {
        const {
            isOpen,
            answer,
            question,
            questionSlide,
            index
        } = this.props

        return (
            <li onClick={_ => { questionSlide(index) }} className={isOpen ? 'open' : null}>
                <a>
                    {question}
                </a>
                <SlideDown>
                    {isOpen ?
                        <div>
                            { processJson(answer) }
                        </div>
                    : null}
                </SlideDown>
            </li>
        )
    }
}
